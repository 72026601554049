<template>
  <div>
    <el-radio v-model="questionAnswer" :label="true" :disabled="!questionAnswer">正确</el-radio>
    <el-radio v-model="questionAnswer" :label="false" :disabled="questionAnswer">错误</el-radio>
  </div>
</template>

<script>
export default {
  name: 'TrueOrFalse',
  props: ['question'],
  data() {
    return {
      questionAnswer: ''
    }
  },
  mounted() {
    this.questionAnswer = this.ytConstant.answerType.getValue(this.question.questionType, this.question.answer)
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/variables';
.el-radio {
  margin-right: 50px;
}
::v-deep .el-radio__inner {
  background: #f0f2f3;
  border-color: #bfc1c6;
}
::v-deep .el-radio__label {
  font-size: @small;
  color: #000000 !important;
}
::v-deep.is-checked {
  .el-radio__inner {
    background-color: #448bff;
    &:after {
      display: none;
    }
  }
}
</style>
