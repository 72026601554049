<template>
  <el-dialog :scrollable="true" :visible="visible" fullscreen class="yt-dialog yt-dialog-default">
    <div slot="title" style="text-align: center; font-size: 20px;">
      测试用例
    </div>
    <!--    <Button @click="this.isAddNewTestcase = true">添加测试用例</Button>-->
    <div class="testcase-item" v-for="(tc, index) in testCases" :key="index">
      <Divider orientation="left">测试用例{{ index + 1 }}</Divider>
      <div class="input-wrapper">
        <InputWrapper style="margin-top: 0;" label="输入">
          <pre class="io-pre">{{ tc.input }}</pre>
        </InputWrapper>
        <InputWrapper style="margin-top: 0;" label="输出">
          <pre class="io-pre">{{ tc.output }}</pre>
        </InputWrapper>
        <div class="grid-action-cell">
          <div class="simple-btn delete-btn" @click="handleDeleteTestcase(tc.id)">
            <JYIcon href="#icon-guanbi1" style="font-size: 16px;" />
            <span>删除</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isAddNewTestcase" class="add-new-testcase">
      <Divider orientation="left">添加新测试用例</Divider>
      <div class="input-wrapper">
        <InputWrapper style="margin-top: 0;" label="输入" align="top">
          <Input v-model="newTestcaseInput" type="textarea" />
        </InputWrapper>
        <InputWrapper style="margin-top: 0;" label="输出" align="top">
          <Input v-model="newTestcaseOutput" type="textarea" />
        </InputWrapper>
        <div>
          <div class="simple-btn save-btn" @click="handleAddTestCase">
            <JYIcon href="#icon-gouxuan" style="font-size: 16px;" />
            <span>保存</span>
          </div>
        </div>
      </div>
    </div>
    <Spin v-if="spinShow" size="large" fix></Spin>
    <div slot="footer" class="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button class="button" type="primary" @click="visible = false">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import InputWrapper from '@components/common/InputWrapper'
import JYIcon from '@components/common/YTIcon'
import JudgeAPI from '@api/judge'
export default {
  name: 'TestCaseMaintainModal',
  components: {
    InputWrapper,
    JYIcon
  },
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      visible: this.value,
      isAddNewTestcase: true,
      newTestcaseInput: '',
      newTestcaseOutput: '',
      testCases: [],
      spinShow: false
    }
  },
  watch: {
    value(newVal) {
      this.visible = newVal
      if (newVal === true) {
        this.requestTestcasesById()
      } else {
        this.resetModalData()
      }
    },
    visible(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    async handleAddTestCase() {
      if (!this.newTestcaseOutput || !this.newTestcaseInput) {
        return
      }
      const payload = {
        input: this.newTestcaseInput,
        output: this.newTestcaseOutput,
        problemId: this.questionId
      }
      try {
        this.spinShow = true
        JudgeAPI.saveTestCase(payload).then(res => {
          if (res.code === 0) {
            this.$emit('refresh')
            this.requestTestcasesById()
          }
        })
        this.spinShow = false
        this.newTestcaseInput = ''
        this.newTestcaseOutput = ''
      } catch (e) {
        this.spinShow = false
      }
    },
    resetModalData() {
      this.testCases = []
    },
    async handleDeleteTestcase(id) {
      try {
        JudgeAPI.deleteTestcaseById(id).then(res => {
          if (res.code === 0) {
            this.$emit('refresh')
            this.requestTestcasesById()
          }
        })
      } catch (e) {}
    },
    setQuestionId(questionId) {
      this.questionId = questionId
    },
    async requestTestcasesById() {
      const { res } = await JudgeAPI.getTestCasesById(this.questionId)
      this.testCases = res
    }
  }
}
</script>

<style lang="less" scoped>
.input-wrapper {
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr 70px;
  grid-column-gap: 10px;
  align-items: center;
}

.grid-action-cell {
  justify-self: center;
}

.simple-btn {
  user-select: none;
  align-self: start;
  cursor: pointer;
  padding: 3px 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(28, 31, 33, 0.15);
  }

  & * + * {
    margin-left: 5px;
  }
}

.save-btn {
  color: darkgreen;
}

.delete-btn {
  color: darkred;
}

.io-pre {
  background: rgba(28, 31, 33, 0.05);
  padding: 2px 5px;
  border: solid 1px rgba(28, 31, 33, 0.2);
  border-radius: 3px;
  max-width: 800px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
